
import Vue from "vue";

import MyInformationChange from "@/components/procedure/MyInformationChange.vue";
import MyPersonChange from "@/components/procedure/MyPersonChange.vue";
import MyCarChange from "@/components/procedure/MyCarChange.vue";
import MyGuarantorChange from "@/components/procedure/MyGuarantorChange.vue";

import { Procedure } from "@/interfaces";

import http from "@/http";

import { Route } from "vue-router";

export interface DataType {
  procedure?: Procedure;
}

export default Vue.extend({
  components: {
    MyInformationChange,
    MyPersonChange,
    MyCarChange,
    MyGuarantorChange,
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/procedures/${to.params.id}`;

    const { data } = await http.get<Procedure>(url);

    next((vm: DataType & Vue) => {
      vm.procedure = data;
    });
  },
  data(): DataType {
    return {
      procedure: undefined,
    };
  },
});
